import Vue from 'vue';
import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class FlagshipGoalsWizardBeforeAdjustGoalsViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  i18n_namespace = 'components.flagship.flagship-goals.flagship_goals_wizard_before_adjust_goals';

  readonly view!: Vue;

  constructor(view: Vue) {
    this.view = view;
    this.view.$emit('loadingStep', false);
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = () => {
    this.view.$emit('nextStep');
  }
}
