

























































import { Component, Vue } from 'vue-property-decorator';
import FlagshipGoalsWizardBeforeAdjustGoalsViewModel
  from '@/vue-app/view-models/components/flagship/flagship-goals/flagship-goals-wizard-before-adjust-goals-view-model';

@Component({ name: 'FlagshipGoalsWizardBeforeAdjustGoals' })
export default class FlagshipGoalsWizardBeforeAdjustGoals extends Vue {
  flagship_goals_wizard_adjust_goals_view_model = Vue.observable(
    new FlagshipGoalsWizardBeforeAdjustGoalsViewModel(this),
  );
}
